<template>
  <div class="outer-page">
    <x-table :no-data-text="CA('technicalGuidance_check') ? '暂无数据' : '暂无数据查看权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @add="add" @page-change="pageChange" @loadExpend="loadExpend" @page-size-change="pageSizeChange"></x-table>
    <Modal v-model="modal.show" :title="modal.title" :width="1200" @on-visible-change="modalChange">
      <Form ref="form" :model="form" :label-width="140" :rules="rules">
        <Row>
          <Col span="12" v-if="modal.title != '回复'">
          <FormItem label="地址" prop="address">
            <Input :readonly="modal.disabled" placeholder="请输入地址" v-model="form.address" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12" v-if="modal.title != '回复'">
          <FormItem label="巡查时间" prop="patrolTime">
            <DatePicker :readonly="modal.disabled" type="date" placeholder="请选择巡查时间" v-model="form.patrolTime" style="width: 80%"></DatePicker>
          </FormItem>
          </Col>
          <Col span="12" v-if="modal.title != '回复'">
          <FormItem label="畜禽种类" prop="typeInfo">
            <Select v-model="form.typeInfo" :disabled="modal.disabled" placeholder="请选择畜禽种类" style="width: 80%">
              <Option v-for="(item, index) in catData" :key="index" :value="item.id + '/' + item.name">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="12" v-if="modal.title != '回复'">
          <FormItem label="巡查级别" prop="inspectionLevel">
            <Select v-model="form.inspectionLevel" :disabled="modal.disabled" placeholder="请选择巡查级别" style="width: 80%">
              <Option value="0">镇街</Option>
              <Option value="1">区级</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="12" v-if="modal.title != '回复'">
          <FormItem label="存栏数" prop="currentInventory">
            <Input :readonly="modal.disabled" placeholder="请输入存栏数" v-model="form.currentInventory" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12" v-if="modal.title != '回复'">
          <FormItem label="存在问题" prop="existProblems">
            <Input :readonly="modal.disabled" type="textarea" :rows="4" placeholder="请输入存在问题" v-model="form.existProblems" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12" v-if="modal.title != '回复'">
          <FormItem label="技术指导人" prop="instructorName">
            <Input :readonly="modal.disabled" type="textarea" :rows="4" placeholder="请输入技术指导人" v-model="form.instructorName" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12" v-if="modal.title != '回复'">
          <FormItem label="技术指导建议" prop="guideSuggestions">
            <Input :readonly="modal.disabled" type="textarea" :rows="4" placeholder="请输入技术指导建议" v-model="form.guideSuggestions" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="8" v-if="modal.title != '回复'">
          <FormItem label="镇街签字" prop="zjSign">
            <upload-image :readonly="modal.disabled" :max="1" :clear-flag="!modal.show" v-model="form.zjSign"></upload-image>
          </FormItem>
          </Col>
          <Col span="8" v-if="modal.title != '回复'">
          <FormItem label="业主签字" prop="ownerSign">
            <upload-image :readonly="modal.disabled" :max="1" :clear-flag="!modal.show" v-model="form.ownerSign"></upload-image>
          </FormItem>
          </Col>
          <Col span="8" v-if="modal.title != '回复'">
          <FormItem label="图片" prop="fileUrl">
            <upload-image :readonly="modal.disabled" :max="10" :clear-flag="!modal.show" v-model="form.fileUrl"></upload-image>
          </FormItem>
          </Col>
          <Col span="8" v-if="modal.title != '回复'">
          <FormItem label="回复图片" prop="replyFileUrl">
            <upload-image :readonly="modal.disabled" :max="10" :clear-flag="!modal.show" v-model="form.replyFileUrl"></upload-image>
          </FormItem>
          </Col>
          <Col span="8" v-if="modal.title != '回复'">
          <FormItem label="回复内容" prop="replyDesc">
            <Input :readonly="modal.disabled" type="textarea" :rows="4" placeholder="请输入回复内容" v-model="form.replyDesc" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="12" v-if="modal.title == '回复'">
          <FormItem label="回复图片" prop="replyFileUrl">
            <upload-image :readonly="modal.disabled" :max="10" :clear-flag="!modal.show" v-model="form.replyFileUrl"></upload-image>
          </FormItem>
          </Col>
          <Col span="12" v-if="modal.title == '回复'">
          <FormItem label="回复内容" prop="replyDesc">
            <Input :readonly="modal.disabled" type="textarea" :rows="4" placeholder="请输入回复内容" v-model="form.replyDesc" style="width: 80%"></Input>
          </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit" v-if="!modal.disabled">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
    <Modal :width="300" v-model="isDelete" title="是否删除" @on-ok="deletes" @on-cancel="
        () => {
          this.isDelete = false;
        }
      ">
      <p>确定删除该条消息？</p>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import pictureView from '@/components/picture_view'
import CU from '@/common/util'
export default {
  name: '',
  components: {
    pictureView,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '巡查级别',
            align: 'center',
            minWidth: 140,
            key: 'inspectionLevel',
            render: (h, { row }) => {
              console.log(row);
              if(row.inspectionLevel==1) return <span>区级</span>
              if(row.inspectionLevel==0) return <span>镇街</span>
              return <span>--</span>
            },
          },
          {
            title: '养殖场（户）',
            align: 'center',
            key: 'companyName',
            minWidth: 180,
            fixed:'left',
          },
          {
            title: '所属镇街',
            minWidth: 180,
            tooltip: true,
            align: 'center',
            key: 'regionCodeName',
          },
          {
            title: '畜禽种类',
            align: 'center',
            minWidth: 140,
            key: 'typeName',
          },
          {
            title: '存在问题',
            align: 'center',
            minWidth: 140,
            key: 'existProblems',
            ellipsis: true,
            tooltip: true,
          },
          {
            title: '技术指导人',
            align: 'center',
            minWidth: 140,
            key: 'instructorName',
            ellipsis: true,
            tooltip: true,
          },
          {
            title: '存栏数',
            align: 'center',
            minWidth: 140,
            key: 'currentInventory',
          },
          {
            title: '仔猪存栏数',
            align: 'center',
            minWidth: 140,
            key: 'pigletNum',
          },
          {
            title: '能繁母猪存栏数',
            align: 'center',
            minWidth: 140,
            key: 'sowsNum',
          },
          {
            title: '巡查时间',
            align: 'center',
            minWidth: 140,
            key: 'patrolTime',
            render: (h, { row }) => {
              let str = row.patrolTime ? row.patrolTime.substring(0, 10) : ''
              return <span>{str}</span>
            },
          },
          {
            title: '图片',
            align: 'center',
            minWidth: 100,
            key: 'fileUrl',
            render: (h, { row }) => {
              if (!row.fileUrl) return <span>--</span>
              return (
                <div style="width:60px;height:60px;padding:4px;text-align:center">
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(
                      row.servicePath,
                      row.fileUrl.indexOf(',') != -1
                        ? row.fileUrl.split(',')[0]
                        : row.fileUrl
                    )}
                    onClick={() =>
                      this.lookPicture(
                        row.fileUrl.indexOf(',') != -1
                          ? row.fileUrl.split(',')[0]
                          : row.fileUrl
                      )
                    }
                  />
                </div>
              )
            },
          },
          {
            title: '业主签字',
            align: 'center',
            minWidth: 100,
            key: 'ownerSign',
            render: (h, { row }) => {
              if (!row.ownerSign) return <span>--</span>
              return (
                <div style="width:60px;height:60px;padding:4px">
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(
                      row.servicePath,
                      row.ownerSign.indexOf(',') != -1
                        ? row.ownerSign.split(',')[0]
                        : row.ownerSign
                    )}
                    onClick={() =>
                      this.lookPicture(
                        row.ownerSign.indexOf(',') != -1
                          ? row.ownerSign.split(',')[0]
                          : row.ownerSign
                      )
                    }
                  />
                </div>
              )
            },
          },
          {
            title: '镇街签字',
            align: 'center',
            minWidth: 100,
            key: 'zjSign',
            render: (h, { row }) => {
              if (!row.zjSign) return <span>--</span>
              return (
                <div style="width:60px;height:60px;padding:4px">
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(
                      row.servicePath,
                      row.zjSign.indexOf(',') != -1
                        ? row.zjSign.split(',')[0]
                        : row.zjSign
                    )}
                    onClick={() =>
                      this.lookPicture(
                        row.zjSign.indexOf(',') != -1
                          ? row.zjSign.split(',')[0]
                          : row.zjSign
                      )
                    }
                  />
                </div>
              )
            },
          },
          {
            title: '回复时间',
            align: 'center',
            minWidth: 140,
            key: 'replyTime',
            render: (h, { row }) => {
              let str = row.replyTime ? row.replyTime.substring(0, 10) : ''
              return <span>{str}</span>
            },
          },
          {
            title: '回复内容',
            align: 'center',
            minWidth: 140,
            key: 'replyDesc',
            ellipsis: true,
            tooltip: true,
          },
          {
            title: '回复状态',
            align: 'center',
            minWidth: 140,
            key: 'status',
            render: (h, { row }) => {
              if (row.status == 1) return <span>已回复</span>
              return <span>未回复</span>
            },
          },
          {
            title: '回复图片',
            align: 'center',
            minWidth: 140,
            key: 'replyFileUrl',
            render: (h, { row }) => {
              if (!row.replyFileUrl) return <span>--</span>
              return (
                <div style="width:60px;height:60px;padding:4px">
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(
                      row.servicePath,
                      row.replyFileUrl.indexOf(',') != -1
                        ? row.replyFileUrl.split(',')[0]
                        : row.replyFileUrl
                    )}
                    onClick={() =>
                      this.lookPicture(
                        row.replyFileUrl.indexOf(',') != -1
                          ? row.replyFileUrl.split(',')[0]
                          : row.replyFileUrl
                      )
                    }
                  />
                </div>
              )
            },
          },
          {
            title: '上报人',
            tooltip: true,
            align: 'center',
            minWidth: 140,
            key: 'userName',
          },
          {
            title: '上报时间',
            minWidth: 140,
            tooltip: true,
            align: 'center',
            key: 'createTime',
          },
          {
            title: '操作',
            width: 250,
            fixed:'right',
            render: (h, { row }) => {
              return (
                <div>
                  {<a onClick={() => this.expert(row)} style="margin-right:10px">导出报告</a>}
                  {this.CA('technicalGuidance_town') && row.status != 1 && (
                    <a
                      style="margin-right:10px"
                      on-click={() => this.reply(row)}
                    >
                      回复
                    </a>
                  )}
                  {this.CA('technicalGuidance_edit') && (
                    <a
                      style="margin-right:10px"
                      on-click={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA('technicalGuidance_delete') && (
                    // <Poptip
                    //   confirm
                    //   transfer
                    //   title="确定删除吗?"
                    //   on-on-ok={() => this.delete(row.id)}
                    // >
                    //   <a>删除</a>
                    // </Poptip>
                    <a on-click={() => this.delete(row.id)}>删除</a>
                  )}
                  {this.CA('technicalGuidance_view') && (
                    <a
                      style="margin-left: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      详情
                    </a>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: '',
        show: false,
        submitLoading: false,
        disabled: false,
      },
      form: {
        id: '',
        regionCode: [],
        regionCodeName: '',
        address: '',
        currentInventory: '',
        patrolTime: '',
        typeInfo: '',
        inspectionLevel: '',
        type: '',
        typeName: '',
        existProblems: '',
        instructorName: '',
        guideSuggestions: '',
        ownerSign: '',
        zjSign: '',
        fileUrl: '',
        replyFileUrl: '',
        replyDesc: '',
        replyTime: '',
      },

      rules: {
        regionCode: [{ required: true, message: '请选择镇街' }],

        address: [{ required: true, message: '请输入地址' }],
        patrolTime: [{ required: true, message: '请选择巡查时间' }],
        typeInfo: [{ required: true, message: '请选择畜禽种类' }],
        existProblems: [{ required: true, message: '请填写存在的问题' }],
        instructorName: [{ required: true, message: '请输入技术指导人姓名' }],
        guideSuggestions: [{ required: true, message: '请输入技术指导建议' }],
        replyDesc: [{ required: true, message: '请输入回复内容' }],
      },
      picture: {
        show: false,
        src: '',
      },
      farmListInfo: [],
      servicePathInfo: '',
      deleteId: '',
      isDelete: false,
      imgSrc: '',
      checkPic: false,
      areaList: [],
      catData: [],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    config() {
      let config = {
        filter: {
          // add: {
          //   addBtnName: '新增',
          //   ca: 'technicalGuidance_add',
          // },
          loadExpend: {
            loadExpendName: '导出',
            ca: 'technicalGuidance_export',
          },
          width: 220,
          filterBox: [
            {
              conditionName: '养殖场名称',
              component: 'input',
              field: 'companyName',
              defaultValue: '',
            },
            {
              conditionName: '上报时间',
              component: 'dateShortcuts',
              type: 'daterange',
              field: 'createTime',
            },
            {
              conditionName: '镇街',
              component: 'cascader',
              field: 'regionCode',
              defaultValue: [],
              data: this.areaList,
              isLoadData: true,
              loadData: this.loadData,
            },
            {
              conditionName: '回复状态',
              component: 'select',
              field: 'status',
              defaultValue: '',
              data: [
                { value: 0, title: '未回复' },
                { value: 1, title: '已回复' },
              ],
              parameterField: 'value',
              showField: 'title',
              clearable: true,
            },
            {
              conditionName: '巡查级别',
              component: 'select',
              field: 'inspectionLevel',
              defaultValue: '',
              data: [
                { value: 0, title: '镇街' },
                { value: 1, title: '区级' },
              ],
              parameterField: 'value',
              showField: 'title',
              clearable: true,
            },
            {
              conditionName: '巡查时间',
              component: 'date',
              type: 'daterange',
              field: 'patrolTime',
              defaultValue: '',
            },
          ],
        },
        page: this.page,
      }
      return config
    },
  },
  methods: {
    getList() {
      if (!this.CA('technicalGuidance_check')) return
      this.table.loading = true
      this.$post(this.$api.INSPECTION_RECORDS.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list
          this.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    search(value) {
      let n
      if (value.regionCode) {
        for (let i = 0; i < value.regionCode.length; i++) {
          n = value.regionCode[value.regionCode.length - 1]
        }
        let obj = {
          ...value,
          regionCode: n,
        }
        this.search_data = obj
        this.getList()
      } else {
        let obj = {
          ...value,
        }
        this.search_data = obj
        this.getList()
      }
    },
    add() {
      this.modal = {
        show: true,
        title: '新增',
        submitLoading: false,
        disabled: false,
      }
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      if (this.form.regionCode) {
        this.form.regionCode = this.form.regionCode.split(',')
      }
      if (this.form.type && this.form.typeName) {
        this.form.typeInfo = this.form.type + '/' + this.form.typeName
        console.log( this.form.typeInfo);
      }
      this.modal = {
        show: true,
        title: '编辑',
        submitLoading: false,
        disabled: false,
      }
    },
    delete(id) {
      this.isDelete = true
      this.deleteId = id
    },
    deletes() {
      this.$post(this.$api.INSPECTION_RECORDS.DELETE, {
        id: this.deleteId,
      }).then(() => {
        this.$Message.success('删除成功！')
        this.getList()
      })
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        let url
        if (params.id) {
          url =
            this.modal.title == '回复'
              ? this.$api.INSPECTION_RECORDS.REPLY
              : this.$api.INSPECTION_RECORDS.UPDATE
        } else {
          url = this.$api.INSPECTION_RECORDS.ADD
        }
        if (this.form.regionCode) {
          params.regionCode = this.form.regionCode.join(',')
        }
        if (this.form.typeInfo) {
          params.type = this.form.typeInfo.split('/')[0]
          params.typeName = this.form.typeInfo.split('/')[1]
        }
        this.modal.submitLoading = true
        this.$post(url, params)
          .then(() => {
            this.$Message.success(
              params.id
                ? this.modal.title == '回复'
                  ? '回复成功！'
                  : '修改成功！'
                : '增加成功！'
            )
            this.getList()
            this.modal.show = false
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },
    modalChange(visible) {
      if (visible) return
      this.form = {
        id: '',
        regionCode: [],
        regionCodeName: '',
        address: '',
        currentInventory: '',
        patrolTime: '',
        typeInfo: '',
        inspectionLevel: '',
        type: '',
        typeName: '',
        existProblems: '',
        instructorName: '',
        guideSuggestions: '',
        ownerSign: '',
        zjSign: '',
        fileUrl: '',
        replyFileUrl: '',
        replyDesc: '',
        replyTime: '',
      }
      this.$refs.form.resetFields()
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      }
    },
    //查看图片
    checkPicInfo(row) {
      this.checkPic = true
      this.imgSrc = row.servicePath + row.img
    },
    //单条导出
    expert(row) {
      let url = this.$api.EXPORT.GUIANCEONE + `?id=${row.id}`
      this.$downloads(url, `${row.companyName}技术指导巡查记录报告`)
    },
    //导出
    loadExpend() {
      let params = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      }
      let url = this.$api.EXPORT.GUIANCE
      this.$download(url, params, '技术指导巡查记录表')
    },
    //详情
    checkInfo(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      if (this.form.regionCode) {
        this.form.regionCode = this.form.regionCode.split(',')
      }
      if (this.form.type && this.form.typeName) {
        this.form.typeInfo = this.form.type + '/' + this.form.typeName
      }
      this.modal = {
        show: true,
        title: '详情',
        submitLoading: true,
        disabled: true,
      }
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: '500100',
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          if (item.hasChild == 'true') {
            item.loading = false
            item.children = []
          }
        })
        this.areaList = res
      })
    },
    loadData(item, callback) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name
            item.value = item.id
          })
          item.children = res
          callback()
        })
        .finally(() => {
          item.loading = false
        })
    },

    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: '2',
      }).then((res) => {
        this.catData = res.list
      })
    },
    reply(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      if (this.form.regionCode) {
        this.form.regionCode = this.form.regionCode.split(',')
      }
      if (this.form.type && this.form.typeName) {
        this.form.typeInfo = this.form.type + '/' + this.form.typeName
      }
      this.modal = {
        show: true,
        title: '回复',
        submitLoading: false,
        disabled: false,
      }
    },
  },
  mounted() {
    this.getAreaList()
    this.getCategory()
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
:deep(.uploadContainer) {
  flex-wrap: wrap;
}
</style>
